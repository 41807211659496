import moment from "moment";

export default function contactsReducer(
  state = {
    dataTimestamp: undefined,
    contacts: [],
    isFetching: false,
    errorMessage: "",
  },
  action
) {
  switch (action.type) {
    case "FETCH_CONTACTS":
      return {
        ...state,
        isFetching: true,
      };
    case "FETCH_CONTACTS_SUCCESS": {
      return {
        ...state,
        contacts: action.payload,
        isFetching: false,
        dataTimestamp: moment().format("DD/MM HH:mm"),
      };
    }
    case "ADDING_NEW_CONTACT":
      return {
        ...state,
        isFetching: true,
      };
    case "DELETE_CONTACT_SUCCESS":
      return {
        ...state,
        contacts: [...state.contacts].filter(
          (item) => item.contact_number !== action.payload
        ),
      };

    case "NEW_CONTACT_ADDED":
      return {
        ...state,
        contacts: [...state.contacts].concat(action.payload),
        isFetching: false,
      };

    default:
      return state;
  }
}
