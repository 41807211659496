import axios from "axios";
import {
  DELETE_CONTACT_API,
  FETCH_CONTACTS_API,
  SAVE_CONTACT_API,
} from "../../../variables/APIENDPOINTS";
export function saveContact(data) {
  return function (dispatch) {
    dispatch({ type: "ADDING_NEW_CONTACT" });
    axios.post(SAVE_CONTACT_API, JSON.stringify(data)).then((res) => {
      dispatch({
        type: "NEW_CONTACT_ADDED",
        payload: res.data.data,
      });
    });
  };
}

export function fetchContacts(serviceArea_code) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_CONTACTS",
    });
    axios
      .post(FETCH_CONTACTS_API, { admin: true, serviceArea_code })
      .then((res) => {
        dispatch({
          type: "FETCH_CONTACTS_SUCCESS",
          payload: JSON.parse(res.data.body),
        });
      });
  };
}
export function deleteContact(contact_number) {
  return function (dispatch) {
    axios.post(DELETE_CONTACT_API, { contact_number }).then((res) => {
      dispatch({
        type: "DELETE_CONTACT_SUCCESS",
        payload: contact_number,
      });
    });
  };
}
