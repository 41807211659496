import React, { useRef, useState } from "react";
import { useDispatch, connect } from "react-redux";
import {
  Card,
  CardBody,
  Button,
  Col,
  Row,
  CardFooter,
  CardHeader,
  Container,
} from "reactstrap";
import { postCovidSumm } from "./redux/covidActions";
import * as XLSX from "xlsx";
import Icon from "../../components/uilibrary/Icon";

const Summary = (props) => {
  const dispatch = useDispatch();
  const [fileUploaded, setFileUploaded] = useState(false);

  // useEffect(() => {
  //   if (props.covidSummdataTimestamp === undefined) {
  //     fetchData();
  //   }
  // }, []);
  // const fetchData = () => {
  //   dispatch(fetchCovidSumm(props.selectedServiceAreaCode));
  // };

  const fileInputField = useRef(null);
  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };
  // const deleteSumm = (covidSumm_Id) => {
  //   dispatch(deleteCovidSumm(covidSumm_Id));
  // };

  const handleUpload = (e) => {
    e.preventDefault();

    let files = e.target.files,
      f = files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const summ = readedData.SheetNames[0];
      const wardSheet = readedData.SheetNames[1];

      const summws = readedData.Sheets[summ];
      const wardSheetws = readedData.Sheets[wardSheet];
      const finalObj = {
        serviceArea_code: props.selectedServiceAreaCode,
        targetGroup_code: props.selectedServiceAreaCode,
      };
      finalObj.covidSumm = XLSX.utils.sheet_to_json(wardSheetws, {
        raw: true,
      })[0];
      finalObj.wardDetails = XLSX.utils.sheet_to_json(summws, {
        raw: true,
      });
      dispatch(postCovidSumm(finalObj));

      setFileUploaded(true);
    };
    reader.readAsBinaryString(f);
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <Col sm="12" className="pr-0">
          <Card
            className="align-items-end p-1"
            style={{ height: "calc(100vh - 200px)" }}
          >
            <CardHeader></CardHeader>
            <CardBody
              className="w-100 center flex-column"
              style={{ background: "#f4f4f4", height: "90%" }}
            >
              <input
                type="file"
                ref={fileInputField}
                onChange={handleUpload}
                style={{ display: "none" }}
              />
              <Icon
                style={{ fontSize: "54px" }}
                iconObj={{
                  family: "material-icon",
                  name: fileUploaded ? "description" : "file_upload",
                }}
              />

              {fileUploaded ? (
                <a
                  className="text-info pointer"
                  onClick={() => setFileUploaded(false)}
                >
                  Remove File
                </a>
              ) : (
                <a className="text-info pointer" onClick={handleUploadBtnClick}>
                  Upload File
                </a>
              )}
            </CardBody>
            <CardFooter className="p-1"></CardFooter>
          </Card>
        </Col>
        {/* <Col sm="8" className="pl-1">
          <Card>
            <CardHeader className="p-1 d-flex justify-content-end">
              <ButtonGroup>
                <Button color="light">{props.covidSummdataTimestamp}</Button>
                <Button color="light" className="d-flex" onClick={fetchData}>
                  <Icon
                    iconObj={{
                      family: "material-icon",
                      name: "autorenew",
                    }}
                  />
                </Button>
              </ButtonGroup>
            </CardHeader>
            <CardBody>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Summary Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {props.isFetching ? (
                    <tr>
                      <th>
                        <div className="is-loading" />
                      </th>
                      <th>
                        <div className="is-loading" />
                      </th>
                    </tr>
                  ) : null}
                  {props.covidSumm.length > 0
                    ? props.covidSumm
                        .sort(function (a, b) {
                          return b.covidSumm_timestamp - a.covidSumm_timestamp;
                        })
                        .map((item, i) => (
                          <tr key={i}>
                            <th scope="row">{item.covidSumm_timestamp}</th>

                            <td>
                              <Button
                                onClick={() => deleteSumm(item.covidSumm_Id)}
                                color="danger"
                              >
                                {" "}
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))
                    : null}
                </tbody>
              </Table>
            </CardBody>
          </Card>{" "}
        </Col> */}
      </Row>
    </Container>
  );
};
export default connect((state) => ({
  covidSumm: state.covidReducer.covidSumm,
  isFetching: state.covidReducer.isFetching,
  covidSummdataTimestamp: state.covidReducer.covidSummdataTimestamp,
  selectedServiceAreaCode: state.authState.selectedServiceAreaCode,
}))(Summary);
