import { Col, Row, Container } from "reactstrap";
import React from "react";
import AddServiceArea from "./AddServiceArea";
import SATable from "./SATable";
const ServiceArea = () => {
  return (
    <Container fluid className="p-0">
      <Row>
        <Col sm="4" className="pr-0">
          <AddServiceArea />
        </Col>
        <Col sm="8" className="pl-1">
          <SATable />
        </Col>
      </Row>
    </Container>
  );
};

export default ServiceArea;
