import { Col, Row, Container } from "reactstrap";
import React from "react";
import ContactsTable from "./ContactsTable";
import ContactsUpload from "./ContactsUpload";

import ContactsForm from "./ContactsForm";

const Contacts = (props) => {
  return (
    <Container fluid className="p-0">
      <Row>
        <Col sm="3" className="pr-0">
          <ContactsForm />
        </Col>
        <Col sm="9" className="pl-1">
          <ContactsTable />
        </Col>
      </Row>
    </Container>
  );
};

export default Contacts;
