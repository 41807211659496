import { combineReducers } from "redux";
import templateConfig from "./templateConfigReducer";
import authState from "./authReducer";
import routerReducer from "./routerReducer";
import locationReducer from "./locationReducer";
import broadcastReducer from "../../views/broadcast/redux/broadcastReducer";
import contactsReducer from "../../views/contacts/redux/contactsReducer";
import covidReducer from "../../views/covid/redux/covidReducer";
import serviceAreaReducer from "../../views/servicearea/redux/serviceAreaReducer";

export default combineReducers({
  templateConfig,
  authState,
  routerReducer,
  locationReducer,
  broadcastReducer,
  contactsReducer,
  covidReducer,
  serviceAreaReducer,
  authState,
});
