import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import thunk from "redux-thunk";
import reducer from "./redux/reducers/";
const middleware = applyMiddleware(thunk);

const persistConfig = {
  key: "storeData",
  storage: storage,
  blacklist: ["authState"], // which reducer want to store
};
const persistedReducer = persistReducer(persistConfig, reducer);
let store = createStore(persistedReducer, middleware);
let persistor = persistStore(store);
export { store, persistor };
