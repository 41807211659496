import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Row,
  Col,
  ButtonGroup,
  Form,
} from "reactstrap";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import Input from "../../components/uilibrary/formcomponent/Input";
import { Auth } from "aws-amplify";
import { fetchOneHubServiceArea } from "../../redux/actions/commonActions";
import Icon from "../../components/uilibrary/Icon";

const AddAdminForm = (props) => {
  const [newUser, setUsername] = useState("");
  const [newPass, setPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [serviceArea_code, setServiceAreaCode] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.dataTimestamp === undefined) {
      fetchData();
    }
  }, []);

  const fetchData = () => {
    dispatch(fetchOneHubServiceArea());
  };

  const submit = () => {
    if (
      newUser != "" &&
      newPass != "" &&
      mobileNumber != "" &&
      email != "" &&
      serviceArea_code != ""
    ) {
      Auth.signUp({
        username: newUser,
        password: newPass,
        attributes: {
          email: email,
          //"phone number": mobileNumber,
          "custom:serviceArea": serviceArea_code.label,

          "custom:serviceArea_code": serviceArea_code.value,
        },
      }).then((res) => {
        alert("success");
      });
    } else {
      alert("Error");
    }
  };
  const options = props.oneHubServiceAreas.map((item) => ({
    value: item.serviceArea_code,
    label: item.serviceArea,
  }));
  return (
    <Card className="align-items-end p-1 h-100">
      <div className="p-1 d-flex justify-content-end">
        <ButtonGroup>
          <Button color="light">{props.dataTimestamp}</Button>
          <Button color="light" className="d-flex" onClick={fetchData}>
            <Icon
              iconObj={{
                family: "material-icon",
                name: "autorenew",
              }}
            />
          </Button>
        </ButtonGroup>
      </div>
      <CardHeader className="w-100 p-1 d-flex flex-row"></CardHeader>
      <CardBody className="w-100">
        <Form>
          <Row>
            <Col>
              <Input
                id="username"
                onChange={(e) => setUsername(e.target.value)}
                value={newUser}
                text="Username"
                minChars="3"
                specialChars="false"
              />
            </Col>
            <Col>
              <Input
                id="password"
                type="password"
                value={newPass}
                onChange={(e) => setPassword(e.target.value)}
                text="Password"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                id="email"
                text="Email Id"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
            <Col>
              <Input
                id="mobileNumber"
                text="Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                type="number"
              ></Input>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Select
                className="basic-single w-50"
                classNamePrefix="themed-select"
                isClearable={true}
                value={serviceArea_code}
                isSearchable={true}
                onChange={(e) => setServiceAreaCode(e)}
                name="targetGroup"
                options={options}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
      <CardFooter className="p-1">
        <Button color="success" onClick={submit}>
          Submit
        </Button>
      </CardFooter>
    </Card>
  );
};

export default connect((state) => ({
  selectedServiceAreaCode: state.authState.selectedServiceAreaCode,
  oneHubServiceAreas: state.locationReducer.oneHubServiceAreas,
  dataTimestamp: state.locationReducer.dataTimestamp,
}))(AddAdminForm);
