import { Col, Row, Container } from "reactstrap";
import React from "react";
import AddAdminForm from "./AddAdminForm";
const Contacts = (props) => {
  return (
    <Container fluid className="p-0">
      <Row>
        <Col sm="4" className="pr-0">
          <AddAdminForm />
        </Col>
        <Col sm="8" className="pl-1"></Col>
      </Row>
    </Container>
  );
};

export default Contacts;
