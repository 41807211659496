import React, { useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Row,
  Col,
  Form,
} from "reactstrap";
import { useDispatch, connect } from "react-redux";
import { saveContact } from "./redux/contactsAction";
import Icon from "../../components/uilibrary/Icon";

import * as XLSX from "xlsx";

const AddContact = (props) => {
  const [fileUploaded, setFileUploaded] = useState(false);

  const dispatch = useDispatch();
  const fileInputField = useRef(null);
  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const handleUpload = (e) => {
    e.preventDefault();

    let files = e.target.files,
      f = files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const contacts = readedData.SheetNames[0];

      const contactsws = readedData.Sheets[contacts];
      const finalObj = {
        serviceArea_code: props.selectedServiceAreaCode,
        targetGroup_code: props.selectedServiceAreaCode,
      };
      finalObj.contacts = XLSX.utils.sheet_to_json(contactsws, {
        raw: true,
      });

      dispatch(saveContact(finalObj));

      setFileUploaded(true);
    };
    reader.readAsBinaryString(f);
  };

  return (
    <Card className="align-items-end p-1 h-100">
      <CardHeader></CardHeader>
      <CardBody
        className="w-100 center flex-column"
        style={{ background: "#f4f4f4", height: "90%" }}
      >
        <input
          type="file"
          ref={fileInputField}
          onChange={handleUpload}
          style={{ display: "none" }}
        />
        <Icon
          style={{ fontSize: "54px" }}
          iconObj={{
            family: "material-icon",
            name: fileUploaded ? "description" : "file_upload",
          }}
        />

        {fileUploaded ? (
          <a
            className="text-info pointer"
            onClick={() => setFileUploaded(false)}
          >
            Remove File
          </a>
        ) : (
          <a className="text-info pointer" onClick={handleUploadBtnClick}>
            Upload File
          </a>
        )}
      </CardBody>
      <CardFooter className="p-1"></CardFooter>
    </Card>
  );
};

export default connect((state) => ({
  selectedServiceAreaCode: state.authState.selectedServiceAreaCode,
}))(AddContact);
