import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";

import React from "react";
import {
  Route,
  Switch,
  Redirect,
  HashRouter as Router,
} from "react-router-dom";
import { createHashHistory } from "history";
import AppContainer from "./AppContainer";

import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import axios from "axios";
import { useDispatch } from "react-redux";
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const hist = createHashHistory();
const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const dispatch = useDispatch();
  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      if (authData !== undefined && authData.signInUserSession != undefined) {
        axios.defaults.headers.common["Authorization"] =
          authData.signInUserSession.idToken.jwtToken;
        dispatch({ type: "AUTH_SUCCESS", payload: authData });
      }
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <Router history={hist}>
      <Switch>
        <Route path="/" exact render={(props) => <Redirect to="/app" />} />

        <Route
          //dispatch={this.props.dispatch}
          path="/app"
          //bypass={true}
          render={(props) => <AppContainer baseURL="/app" />}
        />
      </Switch>
    </Router>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn
        headerText="oneHub | Admin"
        slot="sign-in"
        hideSignUp="true"
      ></AmplifySignIn>
    </AmplifyAuthenticator>
  );
};

export default AuthStateApp;
