import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Row,
  Col,
  Form,
} from "reactstrap";

import Select from "react-select";
import { useDispatch } from "react-redux";
import Input from "../../components/uilibrary/formcomponent/Input";
import { saveServiceArea } from "./redux/serviceAreaAction";

const DISTRICT = [
  { value: "Palakkad", label: "Palakkad" },
  { value: "Thrissur", label: "Thrissur" },
];

const AddServiceArea = (props) => {
  const [serviceArea_code, setServiceArea_code] = useState("");
  const [serviceArea, setServiceArea] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("Kerala");
  const [country, setCountry] = useState("India");

  const dispatch = useDispatch();

  const submit = () => {
    if (
      serviceArea_code != "" &&
      serviceArea != "" &&
      district != "" &&
      state != "" &&
      country != ""
    ) {
      dispatch(
        saveServiceArea({
          serviceArea_code,
          serviceArea,
          district,
          state,
          country,
        })
      );
    } else {
      alert("Error");
    }
  };

  return (
    <Card className="align-items-end p-1 h-100">
      <CardHeader className="w-100 p-1 d-flex flex-row"></CardHeader>
      <CardBody className="w-100">
        <Form>
          <Row>
            <Col>
              <Input
                id="serviceArea_code"
                onChange={(e) => setServiceArea_code(e.target.value)}
                value={serviceArea_code}
                text="Service Area Code"
                minChars="3"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                id="serviceArea"
                value={serviceArea}
                onChange={(e) => setServiceArea(e.target.value)}
                text="Service Area(Panchayath)"
                numbers="false"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                className="basic-single w-100"
                classNamePrefix="themed-select"
                placeholder="Select District"
                isClearable={true}
                isSearchable={true}
                onChange={(e) => setDistrict(e.value)}
                name="district"
                options={DISTRICT}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
      <CardFooter className="p-1">
        <Button color="success" onClick={submit}>
          Submit
        </Button>
      </CardFooter>
    </Card>
  );
};

export default AddServiceArea;
