import moment from "moment";

export default function broadcastReducer(
  state = {
    covidSumm: [],
    covidGuidelines: [],
    isFetchingGuideline: false,
    isFetching: false,
    errorMessage: "",
    covidSummdataTimestamp: undefined,
    covidGuidelinesdataTimestamp: undefined,
  },
  action
) {
  switch (action.type) {
    case "FETCH_COVIDSUMM":
      return {
        ...state,
        isFetching: true,
      };
    case "FETCH_COVIDSUMM_SUCCESS": {
      return {
        ...state,
        covidSumm: action.payload,
        isFetching: false,
        covidSummdataTimestamp: moment().format("DD/MM HH:mm"),
      };
    }
    case "ADDING_NEW_COVIDSUMM":
      return {
        ...state,
        isFetching: true,
      };
    case "DELETE_COVIDSUMM_SUCCESS":
      return {
        ...state,
        covidSumm: [...state.covidSumm].filter(
          (item) => item.covidSumm_Id !== action.payload
        ),
      };

    case "NEW_COVIDSUMM_ADDED": {
      return {
        ...state,
        //covidSumm: [...state.covidSumm].concat(action.payload),
        covidSummdataTimestamp: moment().format("DD/MM HH:mm"),
        isFetching: false,
      };
    }
    case "FETCH_COVIDGUIDELINE":
      return {
        ...state,
        isFetchingGuideline: true,
      };
    case "FETCH_COVIDGUIDELINE_SUCCESS": {
      return {
        ...state,
        covidGuidelines: action.payload,
        isFetchingGuideline: false,
        covidGuidelinesdataTimestamp: moment().format("DD/MM HH:mm"),
      };
    }
    case "ADDING_NEW_COVIDGUIDELINE":
      return {
        ...state,
        isFetchingGuideline: true,
      };
    case "DELETE_COVIDGUIDELINE_SUCCESS":
      return {
        ...state,
        covidGuidelines: [...state.covidGuidelines].filter(
          (item) => item.covidGuide_Id !== action.payload
        ),
      };

    case "NEW_COVIDGUIDELINE_ADDED":
      return {
        ...state,
        // covidGuidelines: [...state.covidGuidelines].concat(action.payload),
        covidGuidelinesdataTimestamp: moment().format("DD/MM HH:mm"),

        isFetchingGuideline: false,
      };

    default:
      return state;
  }
}
