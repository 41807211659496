import React from "react";
import { Switch, Route } from "react-router-dom";
import tinycolor from "tinycolor2";
import Select from "react-select";
import { AmplifySignOut } from "@aws-amplify/ui-react";

// core components
import Topbar from "../components/uilibrary/codespace_ui/Topbar";
import Sidebar from "../components/uilibrary/codespace_ui/Sidebar";
import Content from "../components/uilibrary/codespace_ui/Content";
import BrandLogo from "../components/uilibrary/codespace_ui/BrandLogo";
import Layout from "../components/uilibrary/codespace_ui/Layout";
import { connect } from "react-redux";
import SubRouteDispatcher from "../components/uilibrary/codespace_ui/SubRouteDispatcher";
import Broadcast from "../views/broadcast/Broadcast";

import Contacts from "../views/contacts/Contacts";
import Covid from "../views/covid/Covid";
import ServiceArea from "../views/servicearea/ServiceArea";
import AdminUsers from "../views/adminusers/AdminUsers";

const mapState = (store) => ({
  subRoute: store.routerReducer.subRoute,
  routes: store.routerReducer.routes,
  templateConfig: store.templateConfig.config,
  isAuthenticated: store.authState.isAuthenticated,
  roleName: store.authState.roleName,
  selectedServiceAreaCode: store.authState.selectedServiceAreaCode,
});
class AppContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      oneHubSA: "",
    };
  }
  getView = (path) => {
    switch (path) {
      case "/broadcast": {
        return <Broadcast />;
      }
      case "/contacts": {
        return <Contacts />;
      }
      case "/covid": {
        return <Covid />;
      }
      case "/servicearea":
        return <ServiceArea />;
      case "/adminuser":
        return <AdminUsers />;
      default:
        break;
    }
  };

  // locationSelected = (e) => {
  //   this.setState({ oneHubSA: e });

  //   this.props.dispatch(setServiceLocation(e.value));
  // };

  render() {
    // const options = this.props.oneHubServiceAreas.map((item) => ({
    //   value: item.serviceArea_code,
    //   label: item.serviceArea,
    // }));
    return (
      <Layout wrapperClass="h-100">
        <BrandLogo
          brandContent={
            <h4 className="m-0">
              <img src="./logo192.png" height="35" /> oneHub
              <span className="text-info mx-1">|</span>admin
            </h4>
          }
        />
        <Topbar
          baseURL={this.props.baseURL}
          brandContent={
            <h4 className="m-0">
              <img src="./logo192.png" height="35" /> oneHub
              <span className="text-info ml-1">|</span>admin
            </h4>
          }
          routes={
            this.props.templateConfig.layout === "TOP_NAVIGATION"
              ? this.props.routes
              : this.props.subRoute
          }
          navItems={
            <>
              {/* <li className="nav-item">
                <Select
                  className="basic-single w-50"
                  classNamePrefix="location-select"
                  isClearable={true}
                  value={this.state.oneHubSA}
                  isSearchable={true}
                  onChange={this.locationSelected}
                  name="targetGroup"
                  options={options}
                />
              </li> */}

              <li className="nav-item">
                <AmplifySignOut />
              </li>
              <li className="separator d-lg-none" />
            </>
          }
        />
        <Sidebar
          baseURL={this.props.baseURL}
          routes={
            this.props.templateConfig.layout === "SIDE_NAVIGATION"
              ? this.props.routes.filter((item) => {
                  if (this.props.roleName == "OneHubAdmin") {
                    return item.isSystemAdminModule === true;
                  } else {
                    return item.isSystemAdminModule === false;
                  }
                })
              : this.props.subRoute
          }
        />
        <Content>
          <Switch>
            {this.props.selectedServiceAreaCode != "" &&
              this.props.routes
                .filter((item) => {
                  if (this.props.roleName == "OneHubAdmin") {
                    return item.isSystemAdminModule === true;
                  } else {
                    return item.isSystemAdminModule === false;
                  }
                })
                .map((route, i) => {
                  return (
                    <Route
                      key={i}
                      path={`${this.props.baseURL}${route.path}`}
                      render={(props) => (
                        <SubRouteDispatcher
                          baseURL={this.props.baseURL}
                          parentRoute={`${route.path}`}
                          routes={route.submenu}
                        >
                          {this.getView(route.path)}
                        </SubRouteDispatcher>
                      )}
                    />
                  );
                })}
          </Switch>
        </Content>
      </Layout>
    );
  }
}
export default connect(mapState)(AppContainer);
