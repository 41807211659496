import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  Table,
  Button,
  ButtonGroup,
  CardHeader,
} from "reactstrap";
import {
  fetchBroadcastData,
  deleteBroadCastMsg,
} from "./redux/broadcastActions";
import moment from "moment";
import Icon from "../../components/uilibrary/Icon";

const DataTable = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.dataTimestamp === undefined) {
      fetchData();
    }
  }, []);
  const fetchData = () => {
    dispatch(fetchBroadcastData(props.selectedServiceAreaCode));
  };

  const deleteMsg = (msg_Id) => {
    dispatch(deleteBroadCastMsg(msg_Id));
  };
  return (
    <Card className="h-100">
      <CardHeader className="p-1 d-flex justify-content-end">
        <ButtonGroup>
          <Button color="light">{props.dataTimestamp}</Button>
          <Button color="light" className="d-flex" onClick={fetchData}>
            <Icon
              iconObj={{
                family: "material-icon",
                name: "autorenew",
              }}
            />
          </Button>
        </ButtonGroup>
      </CardHeader>
      <CardBody style={{ height: "calc(100vh - 200px)", overflow: "auto" }}>
        <Table striped hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Messenger</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {props.isFetching ? (
              <tr>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
              </tr>
            ) : null}
            {props.broadcastData.length > 0
              ? props.broadcastData
                  .sort(function (a, b) {
                    return b.msg_timestamp - a.msg_timestamp;
                  })
                  .map((item, i) => (
                    <tr key={i}>
                      <th scope="row">
                        {moment(item.msg_timestamp).format("DD/MM/YYYY HH:mm")}
                      </th>
                      <td>{item.messenger_english}</td>
                      <td>{item.msg_english.substring(0, 10)}</td>
                      <td>
                        <Button
                          onClick={() => deleteMsg(item.msg_Id)}
                          color="danger"
                        >
                          {" "}
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
              : null}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default connect((state) => ({
  broadcastData: state.broadcastReducer.broadcastData,
  isFetching: state.broadcastReducer.isFetching,
  dataTimestamp: state.broadcastReducer.dataTimestamp,

  selectedServiceAreaCode: state.authState.selectedServiceAreaCode,
}))(DataTable);
