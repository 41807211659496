import React, { useState } from "react";
import classnames from "classnames";
import Guidelines from "./Guidelines";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Summary from "./Summary";
const Covid = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Nav pills className="p-1">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Summary
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Guidelines
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="h-100 p-2">
          <Summary />
        </TabPane>
        <TabPane tabId="2" className="h-100 p-2">
          <Guidelines />
        </TabPane>
      </TabContent>
    </>
  );
};

export default Covid;
