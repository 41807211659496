import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Row,
  Col,
  Form,
} from "reactstrap";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import { saveContact } from "./redux/contactsAction";
import Input from "../../components/uilibrary/formcomponent/Input";
import CATOPTIONS from "../../data/category.json";

const ICONOPTIONS = [
  { value: "material-icon", label: "Material Icon" },
  { value: "name", label: "Name Based" },
  { value: "logo", label: "Logo" },
];
const TYPEOPTIONS = [
  { value: "emergency", label: "Emergency" },
  { value: "local", label: "Local" },
];

const AddContact = (props) => {
  const [contact_ward, setContact_ward] = useState("");
  const [contact_number, setContact_number] = useState("");
  const [contact_name_english, setContact_name_english] = useState("");
  const [contact_name_malayalam, setContact_name_malayalam] = useState("");
  const [contact_icon, setContact_icon] = useState("");
  const [contact_icon_value, setContact_icon_value] = useState("");
  const [contact_type, setContact_type] = useState("");
  const [contact_category, setContact_category] = useState("");

  const [contact_role, setContact_role] = useState("");

  const [contact_isDRT, setContact_isDRT] = useState("");

  const [contact_isMessenger, setContact_isMessenger] = useState("");

  const dispatch = useDispatch();

  const submit = () => {
    if (
      contact_name_english != "" &&
      contact_name_malayalam != "" &&
      contact_number != "" &&
      contact_ward != "" &&
      contact_category != "" &&
      contact_icon != "" &&
      contact_icon_value != "" &&
      contact_isDRT != "" &&
      contact_isMessenger != "" &&
      contact_role != "" &&
      contact_type != ""
    ) {
      dispatch(
        saveContact({
          contact_ward,
          contact_name_english,
          contact_name_malayalam,
          contact_number,
          contact_icon,
          contact_icon_value,
          contact_type,
          contact_category,
          contact_role,
          contact_isDRT,
          contact_isMessenger,
          serviceArea_code: props.selectedServiceAreaCode,
          targetGroup_code: props.selectedServiceAreaCode,
        })
      );
    } else {
      alert("Error");
    }
  };

  return (
    <Card className="align-items-end p-1 h-100">
      <CardHeader className="w-100 p-1 d-flex flex-row"></CardHeader>
      <CardBody className="w-100">
        <Form>
          <Row>
            <Col>
              <Input
                id="contact_name_english"
                onChange={(e) => setContact_name_english(e.target.value)}
                value={contact_name_english}
                text="Name"
                minChars="3"
                numbers="false"
                specialChars="false"
              />
            </Col>
            <Col>
              <Input
                id="contact_name_malayalam"
                value={contact_name_malayalam}
                onChange={(e) => setContact_name_malayalam(e.target.value)}
                text="Name in Malayalam"
                numbers="false"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                id="contact_number"
                text="Contact Number"
                value={contact_number}
                onChange={(e) => setContact_number(e.target.value)}
                minChars="10"
                maxChars="14"
                alphabhets="false"
              />
            </Col>
            <Col>
              <Input
                id="contact_ward"
                text="Ward Number"
                value={contact_ward}
                onChange={(e) => setContact_ward(e.target.value)}
                type="number"
                minChars="1"
              ></Input>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Select
                className="basic-single w-100"
                classNamePrefix="themed-select"
                placeholder="Select Logo/Icon"
                isClearable={true}
                isSearchable={true}
                onChange={(e) => setContact_icon(e.value)}
                name="contact_icon"
                options={ICONOPTIONS}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Input
                id="contact_icon_value"
                text="Contact Icon Value"
                value={contact_icon_value}
                onChange={(e) => setContact_icon_value(e.target.value)}
                minChars="1"
              ></Input>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Input
                id="contact_type"
                text="Contact Type"
                value={contact_type}
                onChange={(e) => setContact_type(e.target.value)}
                minChars="1"
              ></Input>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Select
                className="basic-single w-100"
                classNamePrefix="themed-select"
                isClearable={true}
                isSearchable={true}
                placeholder="Select Category"
                onChange={(e) => setContact_category(e.value)}
                name="contact_category"
                options={CATOPTIONS}
              />{" "}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Input
                id="contact_role"
                text="Contact Role"
                value={contact_role}
                onChange={(e) => setContact_role(e.target.value)}
                minChars="1"
              ></Input>
            </Col>
          </Row>

          <Row className="mb-2">
            {" "}
            <Col>
              <Select
                className="basic-single w-100"
                classNamePrefix="themed-select"
                isClearable={false}
                isSearchable={false}
                placeholder="Select is DRT?"
                onChange={(e) => setContact_isDRT(e.value)}
                name="contact_isDRT"
                options={[
                  { value: "no", label: "No" },
                  { value: "yes", label: "Yes" },
                ]}
              />{" "}
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                className="basic-single w-100"
                classNamePrefix="themed-select"
                isClearable={true}
                isSearchable={true}
                placeholder="Select is Messenger?"
                onChange={(e) => setContact_isMessenger(e.value)}
                name="contact_isMessenger"
                options={[
                  { value: "no", label: "No" },
                  { value: "yes", label: "Yes" },
                ]}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
      <CardFooter className="p-1">
        <Button color="success" onClick={submit}>
          Submit
        </Button>
      </CardFooter>
    </Card>
  );
};

export default connect((state) => ({
  selectedServiceAreaCode: state.authState.selectedServiceAreaCode,
}))(AddContact);
