import axios from "axios";
import {
  DELETE_COVIDSUMM_API,
  FETCH_COVIDSUMM_API,
  POST_COVIDSUMM_API,
  POST_COVIDGUIDELINE_API,
  FETCH_COVIDGUIDELINE_API,
  DELETE_COVIDGUIDELINE_API,
} from "../../../variables/APIENDPOINTS";
export function postCovidSumm(data) {
  return function (dispatch) {
    dispatch({ type: "ADDING_NEW_COVIDSUMM" });
    axios.post(POST_COVIDSUMM_API, JSON.stringify(data)).then((res) => {
      dispatch({
        type: "NEW_COVIDSUMM_ADDED",
        payload: res.data.body,
      });
    });
  };
}
export function postCovidGuideline(data) {
  return function (dispatch) {
    dispatch({ type: "ADDING_NEW_COVIDGUIDELINE" });
    axios.post(POST_COVIDGUIDELINE_API, JSON.stringify(data)).then((res) => {
      dispatch({
        type: "NEW_COVIDGUIDELINE_ADDED",
        payload: res.data.body,
      });
    });
  };
}

export function fetchCovidSumm(serviceArea_code) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_COVIDSUMM",
    });
    axios
      .post(FETCH_COVIDSUMM_API, { isAdminData: true, serviceArea_code })
      .then((res) => {
        dispatch({
          type: "FETCH_COVIDSUMM_SUCCESS",
          payload: JSON.parse(res.data.body),
        });
      });
  };
}
export function fetchCovidGuideline(serviceArea_code) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_COVIDGUIDELINE",
    });
    axios
      .post(FETCH_COVIDGUIDELINE_API, { isAdminData: true, serviceArea_code })
      .then((res) => {
        dispatch({
          type: "FETCH_COVIDGUIDELINE_SUCCESS",
          payload: JSON.parse(res.data.body),
        });
      });
  };
}
export function deleteCovidSumm(covidSumm_Id) {
  return function (dispatch) {
    axios.post(DELETE_COVIDSUMM_API, { covidSumm_Id }).then((res) => {
      dispatch({
        type: "DELETE_COVIDSUMM_SUCCESS",
        payload: covidSumm_Id,
      });
    });
  };
}
export function deleteCovidGuideline(covidGuide_Id) {
  return function (dispatch) {
    axios.post(DELETE_COVIDGUIDELINE_API, { covidGuide_Id }).then((res) => {
      dispatch({
        type: "DELETE_COVIDGUIDELINE_SUCCESS",
        payload: covidGuide_Id,
      });
    });
  };
}
