import moment from "moment";
export default function locationReducer(
  state = {
    isFetching: false,
    oneHubServiceAreas: [],
    errorMessage: "",
    dataTimestamp: undefined,
  },
  action
) {
  switch (action.type) {
    case "FETCH_OH_SA":
      return {
        ...state,
      };
    case "FETCH_OH_SA_SUCCESS": {
      return {
        ...state,
        oneHubServiceAreas: action.payload,
        dataTimestamp: moment().format("DD/MM HH:mm"),
      };
    }

    default:
      return state;
  }
}
