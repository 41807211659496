import moment from "moment";

export default function broadcastReducer(
  state = {
    serviceAreas: [],
    isFetching: false,
    errorMessage: "",
    dataTimestamp: undefined,
  },
  action
) {
  switch (action.type) {
    case "FETCH_SA":
      return {
        ...state,
        isFetching: true,
      };
    case "FETCH_SA_SUCCESS": {
      return {
        ...state,
        serviceAreas: action.payload,
        isFetching: false,
        dataTimestamp: moment().format("DD/MM HH:mm"),
      };
    }
    case "SAVE_SERVICE_AREA":
      return {
        ...state,
        isFetching: true,
      };
    case "DELETE_SA_SUCCESS":
      return {
        ...state,
        serviceAreas: [...state.serviceAreas].filter(
          (item) => item.serviceArea_Id !== action.payload
        ),
      };

    case "SAVE_SERVICE_AREA_SUCCESSFULL": {
      return {
        ...state,
        serviceAreas: [...state.serviceAreas].concat(action.payload),
        isFetching: false,
      };
    }

    default:
      return state;
  }
}
