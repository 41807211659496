import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import App from "./app/App";
import Themer from "./app/Themer";
import { store, persistor } from "./store";
import Initializer from "./app/Initializer";
import "./assets/css/styles.css";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Initializer>
          <Themer>
            <App />
          </Themer>
        </Initializer>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
reportWebVitals();
