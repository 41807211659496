import axios from "axios";
import {
  SAVE_SERVICE_AREA,
  DELETE_SERVICE_AREA,
  FETCH_SERVICE_AREA,
} from "../../../variables/APIENDPOINTS";
export function saveServiceArea(data) {
  return function (dispatch) {
    dispatch({ type: "SAVE_SERVICE_AREA" });
    axios.post(SAVE_SERVICE_AREA, JSON.stringify(data)).then((res) => {
      dispatch({
        type: "SAVE_SERVICE_AREA_SUCCESSFULL",
        payload: [res.data.body],
      });
    });
  };
}
export function fetchServiceArea() {
  return function (dispatch) {
    dispatch({
      type: "FETCH_SA",
    });
    axios.get(FETCH_SERVICE_AREA).then((res) => {
      dispatch({
        type: "FETCH_SA_SUCCESS",
        payload: JSON.parse(res.data.body),
      });
    });
  };
}
export function deleteServiceArea(serviceArea_Id) {
  return function (dispatch) {
    axios.post(DELETE_SERVICE_AREA, { serviceArea_Id }).then((res) => {
      dispatch({
        type: "DELETE_SA_SUCCESS",
        payload: serviceArea_Id,
      });
    });
  };
}
