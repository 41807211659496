import moment from "moment";

export default function broadcastReducer(
  state = {
    dataTimestamp: undefined,
    broadcastData: [],
    isFetching: false,
    errorMessage: "",
    messengers: [],
    messengersDataTimestamp: undefined,
  },
  action
) {
  switch (action.type) {
    case "FETCH_BROADCAST_DATA":
      return {
        ...state,
        isFetching: true,
      };
    case "FETCH_BROADCAST_DATA_SUCCESS": {
      return {
        ...state,
        broadcastData: action.payload,
        isFetching: false,
        dataTimestamp: moment().format("DD/MM HH:mm"),
      };
    }
    case "ADDING_NEW_RECORD":
      return {
        ...state,
        isFetching: true,
      };
    case "DELETE_BROADCAST_DATA_SUCCESS":
      return {
        ...state,
        broadcastData: [...state.broadcastData].filter(
          (item) => item.msg_Id !== action.payload
        ),
      };

    case "NEW_RECORD_ADDED":
      return {
        ...state,
        broadcastData: [...state.broadcastData].concat(action.payload),
        isFetching: false,
      };
    case "FETCH_MESSENGERS_SUCCESS":
      return {
        ...state,
        messengers: action.payload.map((item) => ({
          value: item.contact_number,
          label: item.contact_name_english,
          label_malayalam: item.contact_name_malayalam,
          contact_category: item.contact_category,
        })),
        messengersDataTimestamp: moment().format("DD/MM HH:mm"),
      };
    default:
      return state;
  }
}
