import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  ButtonGroup,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import { sendMessage, fetchMessengers } from "./redux/broadcastActions";
import Icon from "../../components/uilibrary/Icon";

const Messenger = (props) => {
  const [previewMsg, setPreviewMsg] = useState(false);
  const [msgMalayalam, setMsgMalayalam] = useState("");
  const [msgEnglish, setMsgEnglish] = useState("");
  const [messenger_english, setMessenger_english] = useState("");
  const [messenger_malayalam, setMessenger_malayalam] = useState("");

  const [messenger_code, setMessengerCode] = useState("");
  const [messenger_category, setMessengerCategory] = useState("");

  const isPreviewEnabled =
    msgMalayalam !== "" && msgEnglish !== "" && messenger_code != "";

  const dispatch = useDispatch();

  const messengerSelected = (e) => {
    if (
      e != undefined &&
      e != null &&
      e.label != undefined &&
      e.value != undefined
    ) {
      setMessenger_english(e.label);

      setMessengerCode(e.value);
      setMessenger_malayalam(
        props.messengers.filter((item) => item.value === e.value)[0]
          .label_malayalam
      );

      setMessengerCategory(
        props.messengers.filter((item) => item.value === e.value)[0]
          .contact_category
      );
    }
  };
  const submit = () => {
    dispatch(
      sendMessage({
        messenger_english: messenger_english,
        messenger_malayalam: messenger_malayalam,

        messenger_code: messenger_code,
        msg_english: msgEnglish,
        msg_malayalam: msgMalayalam,
        serviceArea_code: props.selectedServiceAreaCode,
        targetGroup_code: props.selectedServiceAreaCode,
        messenger_category: messenger_category,
      })
    );
  };

  useEffect(() => {
    if (props.messengersDataTimestamp === undefined) {
      fetchData();
    }
  }, []);
  const fetchData = () => {
    dispatch(fetchMessengers(props.selectedServiceAreaCode));
  };

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <Card className="align-items-end p-1 h-100">
      <CardHeader className="w-100 p-1 d-flex flex-row">
        <Select
          className="basic-single w-100"
          classNamePrefix="select"
          isClearable={false}
          isSearchable={true}
          placeholder="Select Messenger"
          onChange={messengerSelected}
          name="messenger"
          options={props.messengers}
        />
        <Button color="light" className="ml-1 d-flex" onClick={fetchData}>
          <Icon
            iconObj={{
              family: "material-icon",
              name: "autorenew",
            }}
          />
        </Button>
      </CardHeader>
      <CardBody className="w-100">
        <Nav pills className="p-1">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              English
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Malayalam
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={activeTab}
          style={{ background: "#f4f4f4", height: "90%" }}
        >
          <TabPane tabId="1" className="h-100 p-2">
            {previewMsg ? (
              <Card
                style={{ minHeight: 100 }}
                className="p-1"
                dangerouslySetInnerHTML={{ __html: msgEnglish }}
              />
            ) : (
              <textarea
                onChange={(e) => setMsgEnglish(e.target.value)}
                value={msgEnglish}
                className="h-100 w-100 border-0 bg-transparent"
              ></textarea>
            )}
          </TabPane>
          <TabPane tabId="2" className="h-100 p-2">
            {previewMsg ? (
              <Card
                className="p-1"
                style={{ minHeight: 100 }}
                dangerouslySetInnerHTML={{ __html: msgMalayalam }}
              />
            ) : (
              <textarea
                onChange={(e) => setMsgMalayalam(e.target.value)}
                value={msgMalayalam}
                className="h-100 w-100 border-0 bg-transparent"
              ></textarea>
            )}
          </TabPane>
        </TabContent>
      </CardBody>
      <CardFooter className="p-1">
        {!previewMsg ? (
          <Button
            color="primary"
            disabled={!isPreviewEnabled}
            onClick={() => setPreviewMsg(true)}
          >
            Preview
          </Button>
        ) : null}
        {previewMsg ? (
          <ButtonGroup>
            <Button color="primary" onClick={() => setPreviewMsg(false)}>
              Back
            </Button>
            <Button color="success" onClick={submit}>
              Submit
            </Button>
          </ButtonGroup>
        ) : null}
      </CardFooter>
    </Card>
  );
};

export default connect((state) => ({
  messengers: state.broadcastReducer.messengers,
  messengersDataTimestamp: state.broadcastReducer.messengersDataTimestamp,
  selectedServiceAreaCode: state.authState.selectedServiceAreaCode,
}))(Messenger);
