import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Card, CardBody, Table, Button, ButtonGroup } from "reactstrap";
import { deleteServiceArea, fetchServiceArea } from "./redux/serviceAreaAction";

import Icon from "../../components/uilibrary/Icon";

const SATable = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.dataTimestamp === undefined) {
      fetchData();
    }
  }, []);
  const fetchData = () => {
    dispatch(fetchServiceArea());
  };

  const deleteSA = (serviceArea_Id) => {
    dispatch(deleteServiceArea(serviceArea_Id));
  };

  return (
    <Card className="h-100">
      <div className="p-1 d-flex justify-content-end">
        <ButtonGroup>
          <Button color="light">{props.dataTimestamp}</Button>
          <Button color="light" className="d-flex" onClick={fetchData}>
            <Icon
              iconObj={{
                family: "material-icon",
                name: "autorenew",
              }}
            />
          </Button>
        </ButtonGroup>
      </div>
      <CardBody>
        <Table>
          <thead>
            <tr>
              <th>Service Area(Panchayath)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {props.isFetching ? (
              <tr>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
              </tr>
            ) : null}
            {props.serviceAreas.length > 0
              ? props.serviceAreas
                  .sort(function (a, b) {
                    return b.serviceArea - a.serviceArea;
                  })
                  .map((item, i) => (
                    <tr key={i}>
                      <th scope="row">{item.serviceArea}</th>

                      <td>
                        <Button
                          onClick={() => deleteSA(item.serviceArea_Id)}
                          color="danger"
                        >
                          {" "}
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
              : null}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
export default connect((state) => ({
  serviceAreas: state.serviceAreaReducer.serviceAreas,
  isFetching: state.serviceAreaReducer.isFetching,
  dataTimestamp: state.contactsReducer.dataTimestamp,
}))(SATable);
