import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  Table,
  Button,
  ButtonGroup,
  CardHeader,
} from "reactstrap";
import { fetchContacts, deleteContact } from "./redux/contactsAction";
import Icon from "../../components/uilibrary/Icon";

const DataTable = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.dataTimestamp === undefined) {
      fetchData();
    }
  }, []);
  const fetchData = () => {
    dispatch(fetchContacts(props.selectedServiceAreaCode));
  };
  const delContact = (contactId) => {
    dispatch(deleteContact(contactId));
  };
  return (
    <Card className="h-100">
      <CardHeader className="p-1 d-flex justify-content-end">
        <ButtonGroup>
          <Button color="light">{props.dataTimestamp}</Button>
          <Button color="light" className="d-flex" onClick={fetchData}>
            <Icon
              iconObj={{
                family: "material-icon",
                name: "autorenew",
              }}
            />
          </Button>
        </ButtonGroup>
      </CardHeader>
      <CardBody
        className="p-0"
        style={{ height: "calc(100vh - 200px)", overflow: "auto" }}
      >
        <Table striped hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Number</th>
              <th>Ward</th>
              <th>Type</th>
              <th>Category</th>
              <th>Role</th>
              <th>DRT</th>
              <th>Messenger</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.isFetching ? (
              <tr>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
                <th>
                  <div className="is-loading" />
                </th>
              </tr>
            ) : null}
            {props.data.length > 0
              ? props.data.map((item, i) => (
                  <tr key={i}>
                    <th scope="row">{item.contact_name_english}</th>
                    <td>{item.contact_number}</td>
                    <td>{item.contact_ward}</td>
                    <td>{item.contact_type}</td>
                    <td>{item.contact_category}</td>
                    <td>{item.contact_role}</td>
                    <td>{item.contact_isDRT ? "Yes" : "No"}</td>

                    <td>{item.contact_isMessenger ? "Yes" : "No"}</td>

                    <td>
                      <Button
                        onClick={() => delContact(item.contact_number)}
                        color="danger"
                      >
                        {" "}
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default connect((state) => ({
  data: state.contactsReducer.contacts,
  isFetching: state.contactsReducer.isFetching,
  dataTimestamp: state.contactsReducer.dataTimestamp,
  selectedServiceAreaCode: state.authState.selectedServiceAreaCode,
}))(DataTable);
