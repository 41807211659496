import { Col, Row, Container } from "reactstrap";
import React from "react";
import Messenger from "./Messenger";
import BroadcastData from "./BroadcastData";
const Broadcast = (props) => {
  return (
    <Container fluid className="p-0">
      <Row>
        <Col sm="4" className="pr-0">
          <Messenger />
        </Col>
        <Col sm="8" className="pl-1">
          <BroadcastData />
        </Col>
      </Row>
    </Container>
  );
};

export default Broadcast;
