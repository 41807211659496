import axios from "axios";
import { FETCH_SERVICE_AREA } from "../../variables/APIENDPOINTS";

export function fetchOneHubServiceArea() {
  return function (dispatch) {
    dispatch({
      type: "FETCH_OH_SA",
    });

    axios.get(FETCH_SERVICE_AREA).then((res) => {
      dispatch({
        type: "FETCH_OH_SA_SUCCESS",
        payload: JSON.parse(res.data.body),
      });
    });
  };
}
export function setServiceLocation(id) {
  return function (dispatch) {
    dispatch({ type: "SET_SERVICE_AREA", payload: id });
  };
}
