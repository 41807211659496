import axios from "axios";
import {
  DELETE_BROADCAST_API,
  FETCH_BROADCASTDATA_API,
  POST_BROADCAST_API,
  FETCH_MESSENGERS,
} from "../../../variables/APIENDPOINTS";

export function fetchMessengers(serviceArea_code) {
  return function (dispatch) {
    dispatch({ type: "FETCH_MESSENGERS" });
    axios.post(FETCH_MESSENGERS, { serviceArea_code }).then((res) => {
      dispatch({
        type: "FETCH_MESSENGERS_SUCCESS",
        payload: JSON.parse(res.data.body),
      });
    });
  };
}

export function sendMessage(data) {
  return function (dispatch) {
    dispatch({ type: "ADDING_NEW_RECORD" });
    axios.post(POST_BROADCAST_API, JSON.stringify(data)).then((res) => {
      dispatch({
        type: "NEW_RECORD_ADDED",
        payload: res.data.body,
      });
    });
  };
}

export function fetchBroadcastData(serviceArea_code) {
  return function (dispatch) {
    dispatch({
      type: "FETCH_BROADCAST_DATA",
    });
    axios
      .post(FETCH_BROADCASTDATA_API, JSON.stringify({ serviceArea_code }))
      .then((res) => {
        dispatch({
          type: "FETCH_BROADCAST_DATA_SUCCESS",
          payload: JSON.parse(res.data.body),
        });
      });
  };
}
export function deleteBroadCastMsg(msg_Id) {
  return function (dispatch) {
    axios.post(DELETE_BROADCAST_API, { msg_Id }).then((res) => {
      dispatch({
        type: "DELETE_BROADCAST_DATA_SUCCESS",
        payload: msg_Id,
      });
    });
  };
}
