export const FETCH_BROADCASTDATA_API = "/broadcast/getbroadcast";
export const POST_BROADCAST_API = "/broadcast/postbroadcast";
export const DELETE_BROADCAST_API = "/broadcast/deletebroadcast";

export const FETCH_CONTACTS_API = "/contacts/getcontacts";

export const SAVE_CONTACT_API = "/contacts/postcontact";
export const DELETE_CONTACT_API = "/contacts/deletecontact";

export const FETCH_COVIDSUMM_API = "/covid/getsummary";
export const POST_COVIDSUMM_API = "/covid/postsummary";
export const DELETE_COVIDSUMM_API = "/covid/deletesummary";

export const FETCH_COVIDGUIDELINE_API = "/covid/getguidelines";
export const POST_COVIDGUIDELINE_API = "/covid/postguideline";
export const DELETE_COVIDGUIDELINE_API = "/covid/deleteguideline";

export const FETCH_MESSENGERS = "/general/messenger/";

export const SAVE_SERVICE_AREA = "/serviceareas/saveservicearea";
export const DELETE_SERVICE_AREA = "/serviceareas/deleteservicearea";
export const FETCH_SERVICE_AREA = "/serviceareas/getserviceareas";
