/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:1cec529f-52a0-4e0e-9602-e8f00bd3cf27",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_wDbDhd93v",
    "aws_user_pools_web_client_id": "6mhv48op1qbj642ai43v87ssff",
    "oauth": {}
};


export default awsmobile;
