export default function authReducer(
  state = {
    isAuthenticated: false,
    isFetching: false,
    errorMessage: "",
    sessionData: null,
    selectedServiceAreaCode: "",
    roleName: "",
  },
  action
) {
  switch (action.type) {
    case "AUTH_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        selectedServiceAreaCode:
          action.payload.attributes["custom:serviceArea_code"],
        sessionData: action.payload,
        roleName:
          action.payload.signInUserSession.idToken.payload["cognito:groups"][0],
      };
    case "UN_AUTH":
      return {
        ...state,
        isAuthenticated: false,
        sessionData: null,
        selectedServiceAreaCode: "",
        roleName: "",
      };

    default:
      return state;
  }
}
