import React, { useEffect, useRef, useState } from "react";
import { useDispatch, connect } from "react-redux";
import {
  Card,
  CardBody,
  Table,
  Button,
  Col,
  Row,
  CardFooter,
  CardHeader,
  ButtonGroup,
} from "reactstrap";
import {
  fetchCovidGuideline,
  deleteCovidGuideline,
  postCovidGuideline,
} from "./redux/covidActions";
import * as XLSX from "xlsx";
import Icon from "../../components/uilibrary/Icon";

const Guidelines = (props) => {
  const dispatch = useDispatch();
  const [fileUploaded, setFileUploaded] = useState(false);
  // useEffect(() => {
  //   if (props.covidGuidelinesdataTimestamp === undefined) {
  //     fetchData();
  //   }
  // }, []);
  // const fetchData = () => {
  //   dispatch(fetchCovidGuideline(props.selectedServiceAreaCode));
  // };

  const fileInputField = useRef(null);
  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };
  // const deleteGuide = (covidGuide_Id) => {
  //   dispatch(deleteCovidGuideline(covidGuide_Id));
  // };

  const handleUpload = (e) => {
    e.preventDefault();

    let files = e.target.files,
      f = files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const summ = readedData.SheetNames[0];

      const summws = readedData.Sheets[summ];
      const finalObj = {
        serviceArea_code: props.selectedServiceAreaCode,
        targetGroup_code: props.selectedServiceAreaCode,
      };
      finalObj.guidelines = XLSX.utils.sheet_to_json(summws, {
        raw: true,
      });
      setFileUploaded(true);
      dispatch(postCovidGuideline(finalObj));
    };
    reader.readAsBinaryString(f);
  };

  return (
    <Row>
      <Col sm="12" className="pr-0">
        <Card
          className="align-items-end p-1"
          style={{ height: "calc(100vh - 200px)" }}
        >
          <CardHeader></CardHeader>
          <CardBody
            className="w-100 center flex-column"
            style={{ background: "#f4f4f4", height: "90%" }}
          >
            <input
              type="file"
              ref={fileInputField}
              onChange={handleUpload}
              style={{ display: "none" }}
            />
            <Icon
              style={{ fontSize: "54px" }}
              iconObj={{
                family: "material-icon",
                name: fileUploaded ? "description" : "file_upload",
              }}
            />

            {fileUploaded ? (
              <a
                className="text-info pointer"
                onClick={() => setFileUploaded(false)}
              >
                Remove File
              </a>
            ) : (
              <a className="text-info pointer" onClick={handleUploadBtnClick}>
                Upload File
              </a>
            )}
          </CardBody>
          <CardFooter className="p-1"></CardFooter>
        </Card>
      </Col>
      {/* <Col sm="8">
        <Card>
          <CardHeader className="p-1 d-flex justify-content-end">
            <ButtonGroup>
              <Button color="light">
                {props.covidGuidelinesdataTimestamp}
              </Button>
              <Button color="light" className="d-flex" onClick={fetchData}>
                <Icon
                  iconObj={{
                    family: "material-icon",
                    name: "autorenew",
                  }}
                />
              </Button>
            </ButtonGroup>
          </CardHeader>
          <CardBody>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Guideline Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {props.isFetching ? (
                  <tr>
                    <th>
                      <div className="is-loading" />
                    </th>
                    <th>
                      <div className="is-loading" />
                    </th>
                  </tr>
                ) : null}
                {props.covidGuidelines.length > 0
                  ? props.covidGuidelines
                      .sort(function (a, b) {
                        return b.covidGuide_timestamp - a.covidGuide_timestamp;
                      })
                      .map((item, i) => (
                        <tr key={i}>
                          <th scope="row">{item.covidGuide_timestamp}</th>

                          <td>
                            <Button
                              onClick={() => deleteGuide(item.covidGuide_Id)}
                              color="danger"
                            >
                              {" "}
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))
                  : null}
              </tbody>
            </Table>
          </CardBody>
        </Card> 
      </Col>*/}
    </Row>
  );
};
export default connect((state) => ({
  covidGuidelines: state.covidReducer.covidGuidelines,
  isFetching: state.covidReducer.isFetchingGuideline,
  covidGuidelinesdataTimestamp: state.covidReducer.covidGuidelinesdataTimestamp,
  selectedServiceAreaCode: state.authState.selectedServiceAreaCode,
}))(Guidelines);
